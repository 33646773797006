<template>
    <div>
        <v-app-bar
            flat
            color="grey lighten-4"
            dense
            class="elevation-0"
        >   
            <v-icon left>mdi-receipt</v-icon>
            <v-toolbar-title class="body-2">Predračun</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="toggleCardContent('card1')">
                <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
            </v-btn>
        </v-app-bar>

        <v-divider v-show="card1"></v-divider>
        <v-card-text v-show="card1">      
            <v-container grid-list-xl text-x-center align-center>
               
               <!-- transakcijski račun -->
                <v-layout row justify-center v-bind="columnBinding">
                    <v-flex xs12 md4 align-content-center>
                        <v-subheader class="pt-7 pl-0"><strong>TRR</strong></v-subheader>
                    </v-flex>
                    <v-flex xs12 md8 align-content-center>
                        <v-text-field
                            v-model="iban"
                            label="Transakcijski račun"
                            readonly
                            append-icon="mdi-credit-card-settings-outline"
                        ></v-text-field>
                    </v-flex>
                </v-layout> 

                <!-- koda namena -->
                <v-layout row justify-center v-bind="columnBinding">
                    <v-flex xs12 md4 align-content-center>
                        <v-subheader class="pt-7 pl-0"><strong>Koda namena</strong></v-subheader>
                    </v-flex>
                    <v-flex xs12 md8 align-content-center>
                        <v-text-field
                            v-model="code"
                            label="Koda namena"
                            readonly
                            append-icon="mdi-pound-box-outline"
                        ></v-text-field>
                    </v-flex>
                </v-layout> 

                <!-- namen -->
                <v-layout row justify-center v-bind="columnBinding">
                    <v-flex xs12 md4 align-content-center>
                        <v-subheader class="pt-7 pl-0"><strong>Namen plačila</strong></v-subheader>
                    </v-flex>
                    <v-flex xs12 md8 align-content-center>
                        <v-text-field
                            v-model="purpose"
                            label="Namen plačila"
                            readonly
                            append-icon="mdi-bookmark"
                        ></v-text-field>
                    </v-flex>
                </v-layout> 

                <!-- prejemnik -->
                <v-layout row justify-center v-bind="columnBinding">
                    <v-flex xs12 md4 align-content-center>
                        <v-subheader class="pt-7 pl-0"><strong>Prejemnik</strong></v-subheader>
                    </v-flex>
                    <v-flex xs12 md8 align-content-center>
                        <v-text-field
                            v-model="recipient"
                            label="Prejemnik"
                            readonly
                            append-icon="mdi-account"
                        ></v-text-field>
                    </v-flex>
                </v-layout> 

                <!-- referenca -->
                <v-layout row justify-center v-bind="columnBinding">
                    <v-flex xs12 md4 align-content-center>
                        <v-subheader class="pt-7 pl-0"><strong>Referenca</strong></v-subheader>
                    </v-flex>
                    <v-flex xs12 md8 align-content-center>
                        <v-text-field
                            v-model="reference"
                            label="referenca"
                            readonly
                            append-icon="mdi-star-outline"
                        ></v-text-field>
                    </v-flex>
                </v-layout> 
                <!-- znesek za plačilo -->
                <v-layout row justify-center v-bind="columnBinding">
                    <v-flex xs12 md4 align-content-center>
                        <v-subheader class="pt-7 pl-0"><strong>Znesek za plačilo (EUR)</strong></v-subheader>
                    </v-flex>
                    <v-flex xs12 md8 align-content-center>
                        <v-text-field
                            v-model="price"
                            label="Znesek za plačilo (€)"
                            readonly
                            append-icon="mdi-numeric"
                        ></v-text-field>
                    </v-flex>
                </v-layout> 

                  <v-layout row justify-center v-bind="columnBinding">
                    <v-flex xs12 md4 align-content-left v-if="$vuetify.breakpoint.lgAndUp">
                        <v-subheader><strong></strong></v-subheader>
                    </v-flex>
                    <v-flex xs12 md8 align-content-left>
                        <v-btn  class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly" color="#546E7A" dark @click="createPreInvoice()"><v-icon left>mdi-receipt</v-icon>Predračun</v-btn>
                    </v-flex>
                </v-layout>

            </v-container>
        </v-card-text>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { API_ENDPOINT,} from '@/helpers/api'

export default {
    name: 'pre-invoice',
    props: [
        'application',
        'showContent'
    ],

    data: () => ({
        card1: true,
        iban: "SI56 0120 3845 0058 612",
        code: "OTHR",
        purpose: "Plačilo dovolilnice",
        recipient: "Občina Bled, Cesta svobode 13, Bled, 4260 Bled",
        reference: "SI11 75027-7130996", 
        price: "0,00",

        applicationId: null,
        applicationUuid: null

    }),

    watch: {

    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        }
    },

    methods: {
        toggleCardContent(card) {
            if(card == 'card1') {
                this.card1 = !this.card1;
            }
        },

        createPreInvoice() {
            //window.console.log("create pre-invoice...")
            //this.downloadPreInvoice()
            this.viewPreInvoice();
        },

        getPrice() {
            this.$store.dispatch('PARKING_APPLICATION_PRICE',  this.applicationId)
            .then(response =>{
                //window.console.log(response)
                this.price = response.data.total_formatted
            })
            .catch(error => {
                window.console.error(error)
            })
            .finally(() => {
                //window.console.log("PARKING_APPLICATION_PRICE done :)")
            })
        },

        viewPreInvoice() {
            let downloadUrl = API_ENDPOINT + 'v1/files/applications/parking/' + this.applicationId + '/' + this.application.uuid +  '/pre-invoice/view'
            Object.assign(document.createElement('a'), {target: '_blank',href: downloadUrl}).click();
        },

        downloadPreInvoice() {
            let downloadUrl = API_ENDPOINT + 'v1/files/applications/parking/' + this.applicationId + '/pre-invoice/download'
            Object.assign(document.createElement('a'), {href: downloadUrl}).click();
        }
    },

    created() {
        //window.console.log(this.application)
        this.applicationId = this.application.id
        this.applicationUuid = this.applicationUuid

        this.getPrice()
    },

    mounted() {
        var vm = this
        if(this.showContent == true) {
            this.card1 = true;
        }
        else {
            this.card1 = false;
        }

        this.reference = this.reference + "-" + this.application.id

        EventBus.$on('APPLICATION_PRICE', function() {
            vm.getPrice()
        });
    },

    beforeDestroy() {
        EventBus.$off('APPLICATION_PRICE')
    },

    destroyed() {
        
    }
}

</script>

<style scoped>

</style>